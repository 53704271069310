import React, { useState } from 'react';
import { Tabs, Tab, FILL } from 'baseui/tabs-motion';

/**
 * views = [{
 *     title,
 *     content,
 * }]
 * @param {*} props 
 * @returns 
 */
const DemoView = props => {
    const { views } = props;
    const [activeKey, setActiveKey] = useState(views[0].title.toLowerCase());

    const generateTabs = views => {
        return views.map(view => {
            return (
                <Tab 
                key={view.title.toLowerCase()} 
                title={view.title}
                overrides={{
                    Tab: {
                      style: {background: 'transparent'},
                    },
                  }}
                >
                    {view.content}
                </Tab>
            )
        })
    }


    return (
        <Tabs
            activeKey={activeKey}
            onChange={({activeKey}) => setActiveKey(activeKey)}
            fill={FILL.fixed}
        >
            {generateTabs(views)}
        </Tabs>
    )
}

export default DemoView;