import React, { useState } from "react";
import { createTheme } from 'baseui';
import { Button, KIND, SIZE } from 'baseui/button';
import { Input } from 'baseui/input';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import ArrowRight from 'baseui/icon/arrow-right';
import TriangleRight from 'baseui/icon/triangle-right';
import { navigate } from 'gatsby';
import SectionContainer from "../components/SectionContainer";
import DemoView from "../components/DemoView";
import heroProduct from "../static/images/hero-browser.png";
import showcaseProduct from "../static/images/showcase.png";

import { Portal } from 'react-portal';

import './_index.scss';

const primitives = {
    primaryFontFamily: 'Work Sans',
  };
  
const theme = createTheme(primitives);

const IndexPage = () => {
    const [isOpen, setIsOpen] = useState(false);

    const close = () => {
        setIsOpen(false);
    }

    const STEPS = [
        {
            icon: (<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-boxes" viewBox="0 0 16 16">
                        <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z"/>
                    </svg>),
            text: ""    
        },
        {
            icon: (<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-cpu-fill" viewBox="0 0 16 16">
                        <path d="M6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
                        <path d="M5.5.5a.5.5 0 0 0-1 0V2A2.5 2.5 0 0 0 2 4.5H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2v1H.5a.5.5 0 0 0 0 1H2A2.5 2.5 0 0 0 4.5 14v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14h1v1.5a.5.5 0 0 0 1 0V14a2.5 2.5 0 0 0 2.5-2.5h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14v-1h1.5a.5.5 0 0 0 0-1H14A2.5 2.5 0 0 0 11.5 2V.5a.5.5 0 0 0-1 0V2h-1V.5a.5.5 0 0 0-1 0V2h-1V.5a.5.5 0 0 0-1 0V2h-1V.5zm1 4.5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3A1.5 1.5 0 0 1 6.5 5z"/>
                    </svg>),
            text: "Specify over 170 compatible boards for your code." 
        },
        {
            icon: (<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
                    </svg>),
            text: "Easily share your widget as a link or web embed."    
        }
    ];

    const demoViews = [
        {
            title: "Blink",
            content: (<iframe src="https://app.sfbd.is/app/623665dc396618f52f3df9a3"
                title="blink" style={{width:'500px', height:'720px', border:'3px solid #111111', backgroundColor: '#FFFFFF'}}
                allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking; serial"
                sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>)
        },
        {
            title: "Vehicle Tracking",
            content: (<iframe src="https://app.sfbd.is/app/62360093396618f52f3df995"
                title="vehicleTracking" style={{width:'500px', height:'720px', border:'3px solid #111111', backgroundColor: '#FFFFFF'}}
                allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking; serial"
                sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>)
        }
    ]

    return (
        <React.Fragment>
            <div className="layout-wrapper">
                <div className="section-hero">
                    <div className="hero-headline">
                        <div className="hero-text">
                            <h1 className="hero-title display-4">
                                Share your hardware code.
                            </h1>
                            <h4 className="hero-subtitle">
                                Let people edit, compile and upload your hardware code right in the browser. Create yours now.
                            </h4>   
                        </div>
                    </div>
                    <div className="hero-button-cluster">
                        <Button
                            endEnhancer={() => <ArrowRight size={24} />}
                            size={SIZE.large}
                            onClick={() => {
                                navigate("/create");
                                if (typeof window.gtag === "function") {
                                    window.gtag("event", "select_content", { content_type: "Start" });
                                }
                            }}
                        >
                            Create now
                        </Button>
                            <Button 
                                kind={KIND.tertiary} 
                                startEnhancer={() => <TriangleRight size={28}/>} 
                                size={SIZE.large}
                                onClick={() => setIsOpen(true)}
                                >
                                Watch video (5 mins)
                            </Button>
                            <Modal 
                                onClose={close} 
                                isOpen={isOpen}
                                overrides={{
                                    Dialog: {
                                      style: {
                                        width: '80vw',
                                        height: '80vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                      },
                                    },
                                  }}>
                                <ModalHeader>
                                    Surfboard Demo
                                </ModalHeader>
                                <ModalBody>
                                    <iframe className="surfboard-demo" width="100%" height="350%" src="https://www.youtube.com/embed/7l-rLPeP918" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </ModalBody>
                            </Modal>
                    </div>  
                </div>
                <div className="hero-create">
                    <div className="hero-details">
                        <iframe className="surfboard-demo" src="https://www.youtube.com/embed/7l-rLPeP918" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <SectionContainer className="section-demo" fullWidth={true} backgoundColor="dark">
                <div className="demo-wrapper">
                    <div className="demo-description">
                        <div className="demo-headline">
                            <div className="demo-title">
                                <h4 className="demo-title-context">
                                    What is a Surfboard?
                                </h4>
                                <h2 className="demo-title-text">
                                    A widget for your project that inspires your community
                                </h2>
                            </div>
                            <div className="demo-subtitle">
                                <h4 className="demo-subtitle-text">We don't just make another IDE. Surfboard lets you share your hardware code as a URL or HTML embed. Let others quickly run your code on their hardware with zero overhead.</h4>
                            </div>
                            <Button
                            endEnhancer={() => <ArrowRight size={24} />}
                            size={SIZE.compact}
                            onClick={() => {
                                navigate("/create");
                                if (typeof window.gtag === "function") {
                                    window.gtag("event", "select_content", { content_type: "Start" });
                                }
                            }}
                            >
                                Start now
                            </Button>
                        </div>
                        <div className="demo-content">
                            <div className="demo-column-outline">
                                <div className="demo-column">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-gear-wide-connected" viewBox="0 0 16 16">
                                        <path d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646.087.065-.087-.065z"/>
                                    </svg>
                                    <p className="demo-content-title">Easy Setup</p>
                                    <p className="demo-content-text">Just fill up a form. Get your project from development to distribution in seconds.</p>
                                </div>
                            </div>
                            <div className="demo-column-outline">
                                <div className="demo-column">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-plugin" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1 8a7 7 0 1 1 2.898 5.673c-.167-.121-.216-.406-.002-.62l1.8-1.8a3.5 3.5 0 0 0 4.572-.328l1.414-1.415a.5.5 0 0 0 0-.707l-.707-.707 1.559-1.563a.5.5 0 1 0-.708-.706l-1.559 1.562-1.414-1.414 1.56-1.562a.5.5 0 1 0-.707-.706l-1.56 1.56-.707-.706a.5.5 0 0 0-.707 0L5.318 5.975a3.5 3.5 0 0 0-.328 4.571l-1.8 1.8c-.58.58-.62 1.6.121 2.137A8 8 0 1 0 0 8a.5.5 0 0 0 1 0Z"/>
                                    </svg>
                                    <p className="demo-content-title">Rich Support</p>
                                    <p className="demo-content-text">Surfboard supports more than 4,200 libraries and 170 hardware for diverse applications.</p>
                                </div>
                            </div>
                            <div className="demo-column-outline">
                                <div className="demo-column">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
                                    </svg>
                                    <p className="demo-content-title">Simple Distribution</p>
                                    <p className="demo-content-text">Your project is immediately compilable and uploadable through a link or web embed.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="demo-hero">
                        <DemoView views={demoViews} />
                    </div>
                </div>
            </SectionContainer>
            <div className="darken"> 
                <SectionContainer className="section-demo" fullWidth={true} backgoundColor="dark">
                    <div className="demo-wrapper">
                        <div className="demo-description">
                            <div className="demo-headline">
                                <div className="demo-title">
                                    <h4 className="demo-title-context blue">
                                        Highly versatile
                                    </h4>
                                    <h2 className="show-title-text white">
                                        Let your community ride the wave, all tricks included
                                    </h2>
                                </div>
                                <div className="demo-subtitle">
                                    <h4 className="show-subtitle-text light-gray">Once your Surfboard is ready, copy and paste your custom URL to your favourite social platforms, or embed it in your website. Surfboard is lightweight, giving your community a complete prototyping environment in seconds.</h4>
                                </div>
                            </div>
                        </div>
                        <div className="demo-hero">
                            <img className="showcase-image" src={showcaseProduct} alt="Surfboard showcase" />
                        </div>
                    </div>
                </SectionContainer>
            </div>
            <div className="grayer">
                <SectionContainer className="section-signup">
                    <div className="email-update">
                        <h2 className="how-title display-4">
                            Sign up for updates
                        </h2>
                        <form className="form-signup" action="https://sfbd.us14.list-manage.com/subscribe/post" method="POST">
                            <input type="hidden" name="u" value="d38b74d4495c895a02a8f6ef0" />
                            <input type="hidden" name="id" value="bc784f409b" />
                            <Input
                                placeholder="Your email address"
                                name="MERGE0"
                                type="email"
                                overrides={{
                                    Root: {
                                        style: {
                                        marginRight: theme.sizing.scale400,
                                        },
                                    },
                                }}
                            />
                            <Button
                                type="submit"
                            >
                                Submit
                            </Button>
                        </form>
                    </div>
                </SectionContainer>
            </div>
        </React.Fragment>    
                    
    );
}

export default IndexPage
